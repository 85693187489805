<template>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>Centros</b-card-title>
        <b-card-sub-title></b-card-sub-title>
      </div>
    </div>
    <b-card-body >
      <b-form-group >
        <b-form-checkbox-group
          v-model="selected"
          :options="options"
          switches
          class="demo-inline-spacing"
          @change="centroChange"
        />
      </b-form-group>
    </b-card-body>
  </b-card>
</template>

<script>


import
{
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup
} from "bootstrap-vue";

import axios from '@axios' 

export default {

  directives: {
  },

  props: {

    dataUrl: {
      type: String,
      required: true,
    },


  },

  data()
  {
    return {

      timeout: null,

      options: [],
      selected: [],

    };
  },


  components: {
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BCardTitle,
    BCardSubTitle

  },

  created()
  {
    this.getData()
      .then((response) =>
      {

        let options = []
        let selected = []

        response.data.forEach(centro =>
        {

          options.push({ text: centro, value: centro })
          selected.push(centro)

        });


        this.options = options;
        this.selected = selected;

      })
  },


  methods: {

    getData()
    {
      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })
    },


    centroChange(checked)
    {
      if (this.timeout){
          clearTimeout(this.timeout); 
      } 

      this.timeout = setTimeout(() => {
        this.$emit('centro-change', this.selected);
      }, 1000); // delay



    }



  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

