<template>
  <div>
    <!--
    <b-button @click="logout">Logout</b-button>
    -->


    <b-row>
      <b-col class="text-center"> </b-col>
    </b-row>


  

    <b-row>
      <b-col>
        <detalle-produccion
          :dataUrl="tablaDetalleProduccion"
        ></detalle-produccion>
      </b-col>
    </b-row>
  </div>
</template>


<script>




import
{
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";



import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';

import UsoCostosLineas from './UsoCostosLineas.vue'

import DetalleProduccion from './DetalleProduccion.vue'

var qs = require('qs');


import useClientsService from "@/auth/clients/useClientsService";

export default {
  data()
  {

    let query = qs.stringify({ centros: 'all', planificacionId: this.planificationId })


    return {

      estadisticas: useApiServices.detallePlanificacion.planProductivo.estadisticas + "?" + query,

      costoDistribucionUSDMensualChart: useApiServices.detallePlanificacion.planProductivo.costoDistribucionUSDMensualChart + "?" + query,
      costoEmpaqueUSDMensualChart: useApiServices.detallePlanificacion.planProductivo.costoEmpaqueUSDMensualChart + "?" + query,
      costoProcesoTotalUSDMensualChart: useApiServices.detallePlanificacion.planProductivo.costoProcesoTotalUSDMensualChart + "?" + query,

      usoCostosMensualesLineas: useApiServices.detallePlanificacion.planProductivo.usoCostosMensualesLineas + "?" + query,
      tableTop10Produccion: useApiServices.detallePlanificacion.planProductivo.tableTop10Produccion + "?" + query,
      distribucionMensualKgProducidosPorTipo: useApiServices.detallePlanificacion.planProductivo.distribucionMensualKgProducidosPorTipo + "?" + query,

      tablaDetalleProduccion: useApiServices.detallePlanificacion.planProductivo.tablaDetalleProduccion + "?" + query,





    };
  },
  created()
  {
    console.log("PlanCosecha > created")
    console.log(this.planificationId)

    console.log({ useClientsService })

  },

  methods: {

    logout()
    {
      useClientsService.logout()
    },

    centroChange(checked)
    {

      let query = qs.stringify({ centros: checked, planificacionId: this.planificationId })

      this.estadisticas = useApiServices.detallePlanificacion.planProductivo.estadisticas + "?" + query
      this.costoDistribucionUSDMensualChart = useApiServices.detallePlanificacion.planProductivo.costoDistribucionUSDMensualChart + "?" + query
      this.costoEmpaqueUSDMensualChart = useApiServices.detallePlanificacion.planProductivo.costoEmpaqueUSDMensualChart + "?" + query
      this.costoProcesoTotalUSDMensualChart = useApiServices.detallePlanificacion.planProductivo.costoProcesoTotalUSDMensualChart + "?" + query

      this.tableTop10Produccion = useApiServices.detallePlanificacion.planProductivo.tableTop10Produccion + "?" + query

      this.distribucionMensualKgProducidosPorTipo = useApiServices.detallePlanificacion.planProductivo.distribucionMensualKgProducidosPorTipo + "?" + query

      this.tablaDetalleProduccion = useApiServices.detallePlanificacion.planProductivo.tablaDetalleProduccion + "?" + query



    },


  },

  props: {
    planificationId: {
      type: String,
      default: "",
    },
  },

  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardBody,
    BCardGroup,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    BrunaElementLoader,
    UsoCostosLineas,
    DetalleProduccion
  },



};
</script>

<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>
