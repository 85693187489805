<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="showModal">
        <feather-icon icon="AlignJustifyIcon" class="mr-50" />
        <span class="align-middle">Ver Detalles</span>
      </b-button>
    </b-button-group>

    <b-modal
      :ref="'detalle-jaula-modal-' + cellValue.data.id"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      title=""
      hide-footer
      @hidden="modalClose"
    >
      <b-row>
        <b-col>
          <DetalleCalibre :jaula_acopio="cellValue.data"></DetalleCalibre>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-text>{{ cellValue.data }}</b-card-text>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol } from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';


import DetalleCalibre from "./DetalleCalibre.vue";


export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    AgGridTable,
    BrunaElementLoader,
    DetalleCalibre
  },

  directives: {
  },

  data: function ()
  {
    return {
      cellValue: null,



    };
  },
  beforeMount()
  {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);

    //console.log(this.params);

  },
  methods: {

    modalClose()
    {

      let route = [];
      let purge = true;
      this.cellValue.api.refreshServerSideStore({ route: route, purge: purge });


    },

    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    showModal()
    {
      this.$refs['detalle-jaula-modal-' + this.cellValue.data.id].show()
    },

    getValueToDisplay(params)
    {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
