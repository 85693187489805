<template>
    <section>
        <b-row>
            <b-col cols="12">
                <chartjsline-chart
                    :title="`Mortalidad Diaria Numero (N)`"
                    tooltipsText="Mortalidad Diaria Numero (N)"
                    :lineChartData="lineChartDetailPlanning.data"
                    :lineChartOptions="lineChartDetailPlanning.options"
                ></chartjsline-chart>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-card-title>Calibres Planta</b-card-title>
                    <b-card-body>
                        <b-table-simple>
                            <b-thead>
                                <b-tr>
                                    <b-th>Calibres</b-th>
                                    <b-th>Kgs</b-th>
                                    <b-th>%</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, index) in calibre_cosecha" v-bind:key="index">
                                    <b-td>{{ item.calibre }}</b-td>
                                    <b-td>{{ item.peso.toFixed(2) }}</b-td>
                                    <b-td>{{ item.porcentaje.toFixed(2) }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-card-title>Calibres Cosecha</b-card-title>
                    <b-card-body>
                        <b-table-simple>
                            <b-thead>
                                <b-tr>
                                    <b-th>Calibres</b-th>
                                    <b-th>Kgs</b-th>
                                    <b-th>%</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, index) in calibre_plantas" v-bind:key="index">
                                    <b-td>{{ item.calibre }}</b-td>
                                    <b-td></b-td>
                                    <b-td>{{ item.porcentaje }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
    primaryColorShade: "#836AF9",
    yellowColor: "#ffe800",
    successColorShade: "#28dac6",
    warningColorShade: "#ffe802",
    warningLightColor: "#FDAC34",
    infoColorShade: "#299AFF",
    greyColor: "#4F5D70",
    blueColor: "#0c102a",
    blueLightColor: "#84D0FF",
    greyLightColor: "#EDF1F4",
    tooltipShadow: "rgba(0, 0, 0, 0.25)",
    lineChartPrimary: "#666ee8",
    lineChartDanger: "#ff4961",
    labelColor: "#6e6b7b",
    grid_line_color: "rgba(200, 200, 200, 0.2)",
    blueColorH: "#5f95e7",
    blueColorD: "rgba(0, 0, 0, 0.25)",
    blueColorW: "#4F5D70",
    blueColorG: "rgba(200, 200, 200, 0.2)",
    /*
      pcAltum1: "#031133",
      pcAltum2: "#04619B",
      pcAltum3: "#179BDB",
      pcAltum4: "#80BBE7",
      pcAltum5: "#A5D7EF",
      pcAltum6: "#C1E6F4"
      */

    pcAltum7: "#F0AE33",
    pcAltum8: "#9CACB1",
    pcAltum4: "#0058A0",
    pcAltum1: "#B9CDE5",
    pcAltum5: "#E26629",
    pcAltum9: "#0058A0",
    pcAltum6: "#003D7E",

    pcAltum2: "#E46C0A",
    pcAltum3: "#1F497D",
};

import {
    BRow,
    BCol,
    BCardBody,
    BCard,
    BCardText,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
} from "bootstrap-vue";

import chartjslineChart from "@/views/vuexy/charts-and-maps/charts/chartjs/ChartjsLineChart.vue";

export default {
    components: {
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BRow,
        BCol,
        BCardBody,
        BCard,
        BCardText,
        BCardTitle,
        chartjslineChart,
    },
    data() {

        let jaula_numero = this.jaula_acopio.jaula.numero;
        let dia_cosecha = this.jaula_acopio.dia_cosecha;
        let porcentaje_mortalidad_promedio_diaria = this.jaula_acopio.jaula.porcentaje_mortalidad_promedio_diaria;

        let peso_vivo_jaula_kgs = this.jaula_acopio.peso_vivo_jaula_kgs

        let calibres_kgs = this.jaula_acopio.calibres_kgs

        let tabla_calibres = {
            0: "0.00 - 2.00",
            1: "2.00 - 2.86",
            2: "2.86 - 3.22",
            3: "3.22 - 3.63",
            4: "3.63 - 4.08",
            5: "4.08 - 4.54",
            6: "4.54 - 4.99",
            7: "4.99 - 5.44",
            8: "5.44 - 5.67",
            9: "5.67 - 6.03",
            10: "6.03 - 6.35",
            11: "6.35 - 6.89",
            12: "6.89 - 20.00",
            13: "> 20",
        }

        let calibre_cosecha = []
        let value = 0

        for (const calibre_calculado in calibres_kgs)
        {
            let calibre_label = tabla_calibres[calibre_calculado]
            let peso = calibres_kgs[calibre_calculado]


            calibre_cosecha.push({
                value: value,
                calibre: calibre_label,
                peso: peso,
                porcentaje: (peso/peso_vivo_jaula_kgs)*100
            })

            value++
        }


        let data = [];
        let labels = [];

        for (let dia = 0; dia < 365; dia++)
        {
            if (dia <= dia_cosecha)
            {
                let ajuste_mortalidad = Math.pow(1 - porcentaje_mortalidad_promedio_diaria, dia );

                data.push(jaula_numero * ajuste_mortalidad);
                labels.push("Dia " + dia)
            }
        }


        return {
            calibre_cosecha: calibre_cosecha,
            calibre_plantas: [

                { value: 0, calibre: "0 - 4,4", porcentaje: "" },
                { value: 1, calibre: "4,4 - 6,3", porcentaje: "" },
                { value: 2, calibre: "6,3 - 7,1", porcentaje: "" },
                { value: 3, calibre: "7,1 - 8,0", porcentaje: "" },
                { value: 4, calibre: "8,0 - 9,0", porcentaje: "" },
                { value: 5, calibre: "9,0 - 10,0", porcentaje: "" },
                { value: 6, calibre: "10,0 - 11,0", porcentaje: "" },

            ],

            lineChartDetailPlanning: {
                options: {
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        backgroundColor: false,
                        tooltips: {
                            shadowColor: chartColors.greyLightColor,
                            backgroundColor: "#ffffff",
                            titleFontColor: "#000000",
                            bodyFontColor: "#000000",
                            borderColor: "rgba(0, 0, 0, 0.3)",
                            borderWidth: 1,
                            padding: 8,
                        },
                        layout: {
                            padding: {
                                top: -15,
                                bottom: -25,
                                left: -15,
                            },
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                    },
                                    gridLines: {
                                        display: true,
                                        color: chartColors.grid_line_color,
                                        zeroLineColor: chartColors.grid_line_color,
                                    },
                                    ticks: {
                                        fontColor: chartColors.labelColor,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    title: 'Numero',
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                    },
                                    ticks: {
                                        stepSize: 0.5,
                                        min: 0,
                                        max: 7,
                                        fontColor: chartColors.labelColor,
                                    },
                                    gridLines: {
                                        display: true,
                                        color: chartColors.grid_line_color,
                                        zeroLineColor: chartColors.grid_line_color,
                                    },
                                },
                            ],
                        },
                        legend: {
                            position: "top",
                            align: "start",
                            labels: {
                                usePointStyle: true,
                                padding: 25,
                                boxWidth: 9,
                            },
                        },
                    },
                },
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Numero',
                            data: data,
                            borderColor: chartColors.pcAltum4,
                            lineTension: 0.5,
                            pointStyle: "circle",
                            backgroundColor: chartColors.pcAltum4,
                            fill: false,
                            pointRadius: 3,
                            pointHoverRadius: 5,
                            pointHoverBorderWidth: 5,
                            pointBorderColor: "transparent",
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: chartColors.pcAltum4,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                        },

                    ],
                },
            },
        };
    },

    methods: {},

    props: {
        jaula_acopio: {
            type: Object,
        },
    },
};
</script>

<style>
</style>
