<template>
  <div>
    <b-card>
      <b-card-body>
        <b-row class="match-height">
          <b-col xl="12" md="12"> </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body>
        <b-row class="match-height">
          <b-col sm="12"> </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>


<script>




import
{
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";



export default {
  data()
  {
    return {

    };
  },
  created()
  {



  },

  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardBody,
    BCardGroup,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BOverlay,
    BSpinner
  },



};
</script>

<style>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>
