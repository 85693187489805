<template>
  <div>


    <b-row>
      <b-col class="text-center"> </b-col>
    </b-row>

    <b-row>

      <b-col>

        <b-card>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title class="mt-0"></b-card-sub-title>
            </div>
          </div>

          <b-card-body>
            <bruna-fn-obj-constructor ref="bruna_fn_obj_cons" :metrics="metrics" :editable="false"
              v-model="fn_obj_values"></bruna-fn-obj-constructor>

          </b-card-body>
        </b-card>



      </b-col>


    </b-row>




    <b-row>
      <b-col>
      </b-col>
    </b-row>
  </div>
</template>
  
  
<script>




import
{
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";



import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import BrunaFnObjConstructor from "@/views/brunacomponents/BrunaFnObjConstructor.vue";

import useApiServices from '@/services/useApiServices.js';


var qs = require('qs');


import useClientsService from "@/auth/clients/useClientsService";

export default {
  data()
  {

    let query = qs.stringify({ centros: 'all', planificacionId: this.planificationId })


    return {


      metrics: [],

      fn_obj_values: [
        {
          metrics: [],
          weight: 1,
          tipo: 'minimizar'
        }
      ],




    };
  },
  mounted()
  {

    let optimization_id = this.$route.params.planification_id;

    this.getInvocationDetails(optimization_id)



  },

  methods: {



    getInvocationDetails(invocation_id)
    {
      useApiServices.getInvocationDetails(invocation_id)
        .then((response) =>
        {
          console.log("getInvocationDetails", response)

          let invocation_details = response.data

          this.metrics = invocation_details.metrics
          this.fn_obj_values = invocation_details.fn_obj_values


        })

    },




  },

  props: {
    planificationId: {
      type: String,
      default: "",
    },
  },

  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardBody,
    BCardGroup,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    BrunaElementLoader,

    BrunaFnObjConstructor
  },



};
</script>
  
<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}

.icon-plan {
  padding-top: 5%;
}
</style>
  