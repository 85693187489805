<template>
  <div>
    <estadisticas :dataUrl="estadisticas"></estadisticas>

    <b-row>
      <b-col class="text-center">
        <switch-centro
          @centro-change="centroChange"
          :dataUrl="centrosNames"
        ></switch-centro>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="mortalidadYPesoPromedioChart">
        </bruna-element-loader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="distribucionCalibresKgWFEMensualChart">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <jaulas :dataUrl="jaulasCosechadas"></jaulas>
      </b-col>
    </b-row>

    <b-row>
      <b-col> </b-col>
    </b-row>
  </div>
</template>


<script>




import
{
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";

import SwitchCentro from "./SwitchCentro.vue"
import Estadisticas from "./Estadisticas.vue"
import Jaulas from "./Jaulas.vue"


import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';

var qs = require('qs');


export default {
  data()
  {

    let query = qs.stringify({ centros: 'all', planificacionId: this.planificationId })


    return {

      centrosNames: useApiServices.detallePlanificacion.detalleJaula.centrosNames + "?" + query,

      mortalidadYPesoPromedioChart: useApiServices.detallePlanificacion.detalleJaula.mortalidadYPesoPromedioChart + "?" + query,
      estadisticas: useApiServices.detallePlanificacion.detalleJaula.estadisticas + "?" + query,
      distribucionCalibresKgWFEMensualChart: useApiServices.detallePlanificacion.detalleJaula.distribucionCalibresKgWFEMensualChart + "?" + query,
      jaulasCosechadas: useApiServices.detallePlanificacion.detalleJaula.jaulasCosechadas + "?" + query,










    };
  },
  created()
  {
    console.log("PlanCosecha > created")
    console.log(this.planificationId)


  },

  methods: {

    centroChange(checked)
    {

      let query = qs.stringify({ centros: checked, planificacionId: this.planificationId })


      this.mortalidadYPesoPromedioChart = useApiServices.detallePlanificacion.detalleJaula.mortalidadYPesoPromedioChart + "?" + query

      this.estadisticas = useApiServices.detallePlanificacion.detalleJaula.estadisticas + "?" + query
      this.distribucionCalibresKgWFEMensualChart = useApiServices.detallePlanificacion.detalleJaula.distribucionCalibresKgWFEMensualChart + "?" + query

      this.jaulasCosechadas = useApiServices.detallePlanificacion.detalleJaula.jaulasCosechadas + "?" + query


    },


  },

  props: {
    planificationId: {
      type: String,
      default: "",
    },
  },

  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardBody,
    BCardGroup,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    SwitchCentro,
    BrunaElementLoader,
    Estadisticas,
    Jaulas
  },



};
</script>

<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>
