<template>
  <b-row>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{total_kilos_wfe_cosechados}}</h2>
          <span>Total Kilos WFE Cosechados</span>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{total_jaulas_cosechadas}}</h2>
          <span>Total Jaulas Cosechadas</span>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{total_centros_cosechados}}</h2>
          <span>Total Centros Cosechados</span>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="text-center">
        <b-avatar class="mb-1" :variant="`light-primary`" size="45">
          <feather-icon size="21" icon="TrendingUpIcon" />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">{{mortalidad_mensual_promedio}}</h2>
          <span>% Mortalidad Mensual Promedio</span>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>


import
{
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup,
  BAvatar
} from "bootstrap-vue";

import axios from '@axios' 

export default {

  directives: {
  },

  props: {

    dataUrl: {
      type: String,
      required: true,
    },


  },

  data()
  {
    return {

        total_kilos_wfe_cosechados: 0,
        total_jaulas_cosechadas: 0,
        total_centros_cosechados: 0,
        mortalidad_mensual_promedio: 0


    };
  },


  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BCardTitle,
    BCardSubTitle,
    BAvatar

  },

  created()
  {
    this.getData()
      .then((response) =>
      {

        this.total_kilos_wfe_cosechados = response.data.total_kilos_wfe_cosechados
        this.total_jaulas_cosechadas = response.data.total_jaulas_cosechadas
        this.total_centros_cosechados = response.data.total_centros_cosechados
        this.mortalidad_mensual_promedio = response.data.mortalidad_mensual_promedio


      })
  },


  methods: {

    getData()
    {
      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })
    },




  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

