<template>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>Detalle de Planificacion</b-card-title>
        <b-card-sub-title></b-card-sub-title>
      </div>

      <!-- Card Actions -->
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="refresh"
            >
              <feather-icon icon="RefreshCwIcon" size="20" />
            </b-button>
          </li>
          <li>
            <feather-icon
              icon="HelpCircleIcon"
              title=""
              v-b-popover.hover.left=""
              size="20"
            />
          </li>
        </ul>
      </div>
    </div>

    <!-- Card Body  -->
    <b-card-body>
      <ag-grid-vue
        style="width: 100%; height: 500px"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        @grid-ready="onGridReady"
        @first-data-rendered="firstDataRendered"
        :defaultColDef="defaultColDef"
        :autoGroupColumnDef="autoGroupColumnDef"
        :groupDefaultExpanded="groupDefaultExpanded"
        :rowData="rowData"
      ></ag-grid-vue>
    </b-card-body>
  </b-card>
</template>

<script>


import
{
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup,
  BAvatar,
  VBPopover,
  BButton
} from "bootstrap-vue";

import axios from '@axios' 

import { AgGridVue } from "ag-grid-vue";

import JaulasActions from './JaulasActions.vue';

export default {

  directives: {
    "b-popover": VBPopover,
  },

  props: {

    dataUrl: {
      type: String,
      required: true,
    },


  },

  data()
  {
    return {

      columnDefs: [

      ],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true

      },
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
      rowData: null,




    };
  },


  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
    BButton,

    AgGridVue,
    VBPopover,

    jaulasActions: JaulasActions

  },

  created()
  {
    this.updateData()

  },


  methods: {

    updateData()
    {
      this.getData()
        .then((response) =>
        {
          console.log({ response })

          this.columnDefs = response.data.columnDefs;
          this.rowData = response.data.rowData;

          console.log("getData");




        })


    },

    refresh()
    {
      this.updateData()


    },

    firstDataRendered(params)
    {

      console.log("firstDataRendered")

      this.gridColumnApi.autoSizeAllColumns();

    },

    onGridReady(params)
    {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      console.log("onGridReady")

      //const updateData = (data) => params.api.setRowData(data.slice(0, 50));

    },


    getData()
    {
      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })
    },




  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>


<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "@core/scss/vue/pages/ui-feather.scss";

//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

div.ag-watermark {
  display: none !important;
}

</style>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

