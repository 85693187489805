<template>
  <div>
    <b-row>
      <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">
        <stat
          :variant="stat.variant"
          :icon="stat.icon"
          :name="stat.name"
          :value="stat.value"
          :unit="stat.unit"
        ></stat>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-center"> </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="tableTop10Produccion">
        </bruna-element-loader>
      </b-col>

      <b-col>
        <bruna-element-loader :dataUrl="distribucionMensualKgProducidosPorTipo">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <fillrate-semanal-canal
          :dataUrl="tablaFillrateSemanalPerCanal"
        ></fillrate-semanal-canal>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <fillrate-semanal-canal-real
          :dataUrl="tablaFillrateSemanalPerCanalReal"
        ></fillrate-semanal-canal-real>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="consolidadoM2CanalPerFormatoChart">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <consolidado-m2-canal-per-formato-table
          :dataUrl="consolidadoM2CanalPerFormatoTable"
        ></consolidado-m2-canal-per-formato-table>
      </b-col>
    </b-row>

    

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="distribucionProduccionM2PerFormatoChart">
        </bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader :dataUrl="distribucionProduccionM2PerCanalChart">
        </bruna-element-loader>
      </b-col>
    </b-row>


    <b-row>
      <b-col>

        <kardex-table></kardex-table>


      </b-col>

    </b-row>

    
    <!-- <b-row>
      <b-col>
        <detalle-produccion
          :dataUrl="tablaDetalleProduccion"
        ></detalle-produccion>
      </b-col>
    </b-row> -->
   
  </div>
</template>


<script>




import
{
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";


import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';

import UsoCostosLineas from './UsoCostosLineas.vue'

import DetalleProduccion from './DetalleProduccion.vue'
import FillrateSemanalCanal from './FillrateSemanalCanal.vue'
import FillrateSemanalCanalReal from './FillrateSemanalCanalReal.vue'

import ConsolidadoM2CanalPerFormatoTable from './ConsolidadoM2CanalPerFormatoTable.vue'


import Stat from '../../../base/components/Stat.vue'

var qs = require('qs');
import axios from '@axios'


import useClientsService from "@/auth/clients/useClientsService";
import ConsolidadoM2CanalPerFormatoTableVue from './ConsolidadoM2CanalPerFormatoTable.vue';
import KardexTable from './KardexTable.vue';

export default {
  data()
  {

    let query = qs.stringify({ centros: 'all', planificacionId: this.planificationId })


    return {



      usoCostosMensualesLineas: useApiServices.detallePlanificacion.planProductivo.usoCostosMensualesLineas + "?" + query,
      tableTop10Produccion: useApiServices.detallePlanificacion.planProductivo.tableTop10Produccion + "?" + query,

      tableKardex: useApiServices.detallePlanificacion.planProductivo.tableKardex + "?" + query,
      

      distribucionMensualKgProducidosPorTipo: useApiServices.detallePlanificacion.planProductivo.distribucionMensualKgProducidosPorTipo + "?" + query,

      tablaDetalleProduccion: useApiServices.detallePlanificacion.planProductivo.tablaDetalleProduccion + "?" + query,

      tablaFillrateSemanalPerCanal: useApiServices.detallePlanificacion.planProductivo.tablaFillrateSemanalPerCanal + "?" + query,
      tablaFillrateSemanalPerCanalReal: useApiServices.detallePlanificacion.planProductivo.tablaFillrateSemanalPerCanalReal + "?" + query,
      consolidadoM2CanalPerFormatoChart: useApiServices.detallePlanificacion.planProductivo.consolidadoM2CanalPerFormatoChart + "?" + query,

      consolidadoM2CanalPerFormatoTable: useApiServices.detallePlanificacion.planProductivo.consolidadoM2CanalPerFormatoTable + "?" + query,

      distribucionProduccionM2PerFormatoChart: useApiServices.detallePlanificacion.planProductivo.distribucionProduccionM2PerFormatoChart + "?" + query,

      distribucionProduccionM2PerCanalChart: useApiServices.detallePlanificacion.planProductivo.distribucionProduccionM2PerCanalChart + "?" + query,

      stats: []



    };
  },

  mounted()
  {

    let query = qs.stringify({ planificacionId: this.planificationId })

    axios.get(useApiServices.detallePlanificacion.planProductivo.estadisticas + "?" + query)
      .then((response) =>
      {
        this.stats = response.data;
      })
      .catch((error) =>
      {

      });


  },

  created()
  {
    console.log("PlanCosecha > created")
    console.log(this.planificationId)

    console.log({ useClientsService })

  },

  methods: {

    logout()
    {
      useClientsService.logout()
    },

    centroChange(checked)
    {

      let query = qs.stringify({ centros: checked, planificacionId: this.planificationId })


      this.tableTop10Produccion = useApiServices.detallePlanificacion.planProductivo.tableTop10Produccion + "?" + query

      this.distribucionMensualKgProducidosPorTipo = useApiServices.detallePlanificacion.planProductivo.distribucionMensualKgProducidosPorTipo + "?" + query

      this.tablaDetalleProduccion = useApiServices.detallePlanificacion.planProductivo.tablaDetalleProduccion + "?" + query



    },


  },

  props: {
    planificationId: {
      type: String,
      default: "",
    },
  },

  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardBody,
    BCardGroup,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    BrunaElementLoader,
    UsoCostosLineas,
    DetalleProduccion,
    Stat,
    FillrateSemanalCanal,
    ConsolidadoM2CanalPerFormatoTable,
    FillrateSemanalCanalReal,
    KardexTable
  },



};
</script>

<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>