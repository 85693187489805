<template>
  <div>
    <estadisticas :dataUrl="estadisticas"></estadisticas>

    <b-row>
      <b-col class="text-center">
        <switch-centro
          @centro-change="centroChange"
          :dataUrl="centrosNames"
        ></switch-centro>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="distribucionCosechaPorCentroChart">
        </bruna-element-loader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="distribucionCosechaPorEspecieChart">
        </bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader :dataUrl="mortalidadYPesoPromedioChart">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader
          :dataUrl="tableTop10Mortalidad"
        ></bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader
          :dataUrl="tableTop10PesoPlanta"
        ></bruna-element-loader>


      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <jaulas :dataUrl="jaulasCosechadas"></jaulas>
      </b-col>
    </b-row>
  </div>
</template>


<script>




import
{
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BButton,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";

import SwitchCentro from "./SwitchCentro.vue"
import Estadisticas from "./Estadisticas.vue"
import Jaulas from "./Jaulas.vue"


import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';

var qs = require('qs');


export default {
  data()
  {

    let query = qs.stringify({ centros: 'all', planificacionId: this.planificationId })


    return {

      centrosNames: useApiServices.detallePlanificacion.planCosecha.centrosNames + "?" + query,
      distribucionCosechaPorCentroChart: useApiServices.detallePlanificacion.planCosecha.distribucionCosechaPorCentroChart + "?" + query,
      distribucionCosechaPorEspecieChart: useApiServices.detallePlanificacion.planCosecha.distribucionCosechaPorEspecieChart + "?" + query,
      mortalidadYPesoPromedioChart: useApiServices.detallePlanificacion.planCosecha.mortalidadYPesoPromedioChart + "?" + query,

      estadisticas: useApiServices.detallePlanificacion.planCosecha.estadisticas + "?" + query,

      jaulasCosechadas: useApiServices.detallePlanificacion.planCosecha.jaulasCosechadas + "?" + query,

      tableTop10Mortalidad: useApiServices.detallePlanificacion.planCosecha.tableTop10Mortalidad + "?" + query,
      tableTop10PesoPlanta: useApiServices.detallePlanificacion.planCosecha.tableTop10PesoPlanta + "?" + query,




    };
  },
  created()
  {
    console.log("PlanCosecha > created")
    console.log(this.planificationId)


  },

  methods: {

    centroChange(checked)
    {

      let query = qs.stringify({ centros: checked, planificacionId: this.planificationId })

      this.distribucionCosechaPorCentroChart = useApiServices.detallePlanificacion.planCosecha.distribucionCosechaPorCentroChart + "?" + query
      this.distribucionCosechaPorEspecieChart = useApiServices.detallePlanificacion.planCosecha.distribucionCosechaPorEspecieChart + "?" + query
      this.mortalidadYPesoPromedioChart = useApiServices.detallePlanificacion.planCosecha.mortalidadYPesoPromedioChart + "?" + query

      this.estadisticas = useApiServices.detallePlanificacion.planCosecha.estadisticas + "?" + query

      this.tableTop10Mortalidad = useApiServices.detallePlanificacion.planCosecha.tableTop10Mortalidad + "?" + query
      this.tableTop10PesoPlanta = useApiServices.detallePlanificacion.planCosecha.tableTop10PesoPlanta + "?" + query


    },


  },

  props: {
    planificationId: {
      type: String,
      default: "",
    },
  },

  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardBody,
    BCardGroup,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    SwitchCentro,
    BrunaElementLoader,
    Estadisticas,
    Jaulas
  },



};
</script>

<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>
